import React from "react"
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Logo from "../../images/logo_alt.svg"

import "./index.css"

const Header = () => (
    <header className="fiki-header">
        <div className="header-logo">
            <AnchorLink href="#home"> 
                <img className="cookhub-logo-small" src={Logo} alt="cookhub_logo"/>
            </AnchorLink>
        </div>

        <div className="header-nav-links">
            <AnchorLink href="#milestones"> Milestones </AnchorLink>
            <AnchorLink href="#team"> The Team </AnchorLink>
        </div>
    </header>
)

export default Header
